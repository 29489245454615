<template>
  <div class="app-container container-crud-popover">
    <div v-scrollTop>
      <avue-crud
        ref="crud"
        :option="tableOption"
        :data="tableData"
        :page="tablePage"
        :tableLoading="tableLoading"
        @size-change="sizeChange"
        @current-change="pageChange"
        @search-change="searchChange"
      >
        <template #sendTimeSearchForm="{ form, prop }">
          <SaleDateTime :time.sync="form[prop]" @change="searchChange"></SaleDateTime>
        </template>

        <template #orderCount="{ row, prop }">
          <span>{{ row.orderCount || 0 }}</span>
          <el-button
            v-p="['externaladmin:orderManage:cutoffBatch:orderNumberDetails']"
            type="text"
            class="ml10"
            @click="viewDetail(row)"
            >查看</el-button
          >
        </template>

        <template #isKnifeLayoutCreated="{ row }">
          <div>已生成*{{ row.createdCount || 0 }}</div>
          <div>未生成*{{ row.waitCreateCount || 0 }}</div>
        </template>

        <template #menu="{ row }">
          <el-button
            class="menu-btn-item"
            type="text"
            @click="viewDetails(row.id)"
            v-p="['externaladmin:orderManage:cutoffBatch:detail']"
            >查看详情</el-button
          >
          <loadingBtn
            class="menu-btn-item"
            type="text"
            @click="orderExport(row)"
            v-p="['externaladmin:orderManage:cutoffBatch:export']"
            >导出</loadingBtn
          >
        </template>
      </avue-crud>
      <viewDetails
        v-if="dialogVisible"
        category="cut"
        :visible.sync="dialogVisible"
        :detailData="dialogData"
      ></viewDetails>
    </div>
  </div>
</template>

<script>
import avueCrud from '@/mixins/avueCrud'
import { tableOption } from './const'
import { cutOffBatchList as getList } from '@/api/order/batch'
import { orderList, exportCutoffBatch } from '@/api/order'
import saveAs from 'jszip/vendor/FileSaver.js'
import SaleDateTime from '@/components/saleDateTime'
import viewDetails from '../sendToFactoryBatch/viewDetails/details'

export default {
  name: 'orderSendToFactoryBatch',
  mixins: [
    avueCrud({
      tableOption,
      getList
    })
  ],
  components: {
    SaleDateTime,
    viewDetails
  },
  data() {
    return {
      dialogData: {},
      dialogVisible: false,
      resetMergeData: {
        isReturnRelatedData: 1,
        orderItems: [
          {
            asc: false,
            column: 'cutoff_time'
          },
          {
            asc: false,
            column: 'id'
          }
        ]
      }
    }
  },
  created() {},
  methods: {
    viewDetail(row) {
      this.dialogData = row
      this.dialogVisible = true
    },
    async orderExport(row) {
      if (row.orderCount <= 0) {
        this.$message.warning('该截单批次下无订单！')
        return
      }

      let res = await awaitFormResolve(
        exportCutoffBatch({
          cutoffBatchId: row.id
        })
      )
      if (!res) return this.$message.warning('导出失败')

      saveAs(res, '截单订单')
      this.$message.success('导出成功')
    },
    viewDetails(sendBatchId) {
      this.$router.push({
        path: '/orderManage/cutoffBatch/viewDetails',
        query: {
          sendBatchId
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
