var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-container container-crud-popover"},[_c('div',{directives:[{name:"scrollTop",rawName:"v-scrollTop"}]},[_c('avue-crud',{ref:"crud",attrs:{"option":_vm.tableOption,"data":_vm.tableData,"page":_vm.tablePage,"tableLoading":_vm.tableLoading},on:{"size-change":_vm.sizeChange,"current-change":_vm.pageChange,"search-change":_vm.searchChange},scopedSlots:_vm._u([{key:"sendTimeSearchForm",fn:function(ref){
var form = ref.form;
var prop = ref.prop;
return [_c('SaleDateTime',{attrs:{"time":form[prop]},on:{"update:time":function($event){return _vm.$set(form, prop, $event)},"change":_vm.searchChange}})]}},{key:"orderCount",fn:function(ref){
var row = ref.row;
var prop = ref.prop;
return [_c('span',[_vm._v(_vm._s(row.orderCount || 0))]),_c('el-button',{directives:[{name:"p",rawName:"v-p",value:(['externaladmin:orderManage:cutoffBatch:orderNumberDetails']),expression:"['externaladmin:orderManage:cutoffBatch:orderNumberDetails']"}],staticClass:"ml10",attrs:{"type":"text"},on:{"click":function($event){return _vm.viewDetail(row)}}},[_vm._v("查看")])]}},{key:"isKnifeLayoutCreated",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v("已生成*"+_vm._s(row.createdCount || 0))]),_c('div',[_vm._v("未生成*"+_vm._s(row.waitCreateCount || 0))])]}},{key:"menu",fn:function(ref){
var row = ref.row;
return [_c('el-button',{directives:[{name:"p",rawName:"v-p",value:(['externaladmin:orderManage:cutoffBatch:detail']),expression:"['externaladmin:orderManage:cutoffBatch:detail']"}],staticClass:"menu-btn-item",attrs:{"type":"text"},on:{"click":function($event){return _vm.viewDetails(row.id)}}},[_vm._v("查看详情")]),_c('loadingBtn',{directives:[{name:"p",rawName:"v-p",value:(['externaladmin:orderManage:cutoffBatch:export']),expression:"['externaladmin:orderManage:cutoffBatch:export']"}],staticClass:"menu-btn-item",attrs:{"type":"text"},on:{"click":function($event){return _vm.orderExport(row)}}},[_vm._v("导出")])]}}])}),(_vm.dialogVisible)?_c('viewDetails',{attrs:{"category":"cut","visible":_vm.dialogVisible,"detailData":_vm.dialogData},on:{"update:visible":function($event){_vm.dialogVisible=$event}}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }